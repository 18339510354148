<template>
  <div>
    <div>
      <b-card>
        <div class="d-flex">
          <span class="h2 mr-auto text-secondary"> Nouvelle Session</span>

          <div class="row mb-3">
            <div class="col-12">
              <modal-actions @close="$router.go(-1)" />
            </div>
          </div>
        </div>
        <div class="shdow mb-2">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <span class="h3 text-secondary">Informations générales</span>

            <b-button pill type="submit" @click="savedata">Confirmer</b-button>
          </div>
          <hr class="mt-1 mb-3 bg-secondary" />

          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group
                label="Nom de session :"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-input
                  type="text"
                  required
                  v-model="newSession.name"
                  :placeholder="'Nom de session'"
                  :class="{
                    'is-invalid':
                      $v.newSession.name.$error && $v.newSession.name.$dirty,
                  }"
                ></b-form-input>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newSession.name.$dirty"
                >
                  {{ !$v.newSession.name.required ? "champ obligatoire" : "" }}
                </span>
              </b-form-group>
              <b-form-group
                label="Date :"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <div class="font-weight-normal">
                  <date-picker
                    :value="new Date()"
                    :placeholder="'Date de création'"
                    v-model="newSession.date"
                    format="DD/MM/YYYY"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newSession.date.$error && $v.newSession.date.$dirty,
                    }"
                  ></date-picker>
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newSession.date.$dirty"
                  >
                    {{
                      !$v.newSession.date.required ? "champ obligatoire" : ""
                    }}
                  </span>
                </div>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group
                label="Planning :"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <div class="font-weight-normal">
                  <v-select
                    :options="planning"
                    label="text"
                    :reduce="(plan) => plan.value"
                    placeholder="Choisir une Planning"
                    name="role"
                    v-model="newSession.planning"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newSession.planning.$error &&
                        $v.newSession.planning.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newSession.planning.$dirty"
                  >
                    {{
                      !$v.newSession.planning.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
        <hr style="position: relative; top: 15px" />
        <div class="row">
          <div class="col-12">
            <b-tabs class="tab-solid tab-solid-primary mt-3">
              <div class="row mb-3">
                <div class="col-12 col-md-6">
                  <div class="d-flex">
                    <div>
                      <h4 class="h4 text-secondary my-3">
                        Liste des Inscriptions
                      </h4>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    col-12 col-md-6
                    d-flex
                    justify-content-end
                    align-btn-table-2
                  "
                >
                  <button
                    type="button"
                    class="
                      btn
                      h1
                      mb-2
                      test-btn
                      btn-secondary
                      d-flex
                      align-items-center
                    "
                    variant="secondary"
                    style="
                      background-color: rgb(0, 123, 255) !important;
                      border-color: rgb(0, 123, 255) !important;
                    "
                    disabled
                  >
                    <i class="mdi mdi-plus mr-2"></i>
                    Nouvelle inscription
                  </button>
                </div>
                <div class="col-12">
                  <hr class="mt-1 mb-3 bg-secondary" />
                </div>
              </div>
              <b-tab title="Inscrits">
                <session-inscription-table :items="[]" :disabled="true" />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import SessionInscriptionTable from "../components/SessionInscriptionTable.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    SessionInscriptionTable,
    ModalActions,
  },
  data: () => ({
    selecteddata: [],
    selectedPlanning: [],
    informatedDate: "",
    newSession: {
      name: "",
      date: new Date(),
      planning: "",
    },
    legalEntity: {
      tableFields: [
        { key: "role", label: "Rôle", sortable: true },
        { key: "user", label: "Utilisateur", sortable: true },
        { key: "session", label: "Session", sortable: true },
        { key: "folder", label: "Dossier", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "actions", label: "", sortable: false },
      ],
      tableItems: [
        {
          role: "Présentateur",
          user: {
            name: "J.D",
            full_name: "John Doe",
            img: "https://source.unsplash.com/random/150x150",
          },
          session: "session 1",
          folder: "Dossier 1",
          date: "02-01-2021",
          actions: ["show", "edit", "delete"],
        },
      ],
    },
  }),
  methods: {
    savedata: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var data = {
          name: this.newSession.name,
          date: moment(this.newSession.date).format("DD/MM/YYYY HH:mm:ss"),
          planning: `/api/gouvernance/plannings/${this.newSession.planning}`,
        };
        if (
          this.newSession.name !== "" &&
          this.newSession.date !== "" &&
          this.newSession.planning !== ""
        ) {
          this.$store.dispatch("manage/createSession", data).then(() => {
            this.submitingForm = false;
            Swal.fire({
              title: "Le Session est bien créé !",
              type: "success",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                this.$router.push({
                  name: "show-session",
                  params: {
                    slug: this.SESSION.slug ? this.SESSION.slug : "5958ds7",
                    id: this.SESSION.id,
                    fromCreation: true,
                  },
                });
              }
            });
          });
        }
      }
    },
  },
  validations: {
    newSession: {
      name: {
        required,
      },
      date: {
        required,
      },
      planning: {
        required,
      },
    },
  },
  watch: {
    PLANNINGS() {
      this.selecteddata = this.PLANNINGS;
    },
  },
  created() {
    this.$store.dispatch("planning/fetchAllPlans");
  },

  computed: {
    ...mapGetters("planning", ["PLANNINGS"]),
    ...mapGetters("manage", ["SESSION"]),

    planning() {
      var data = this.PLANNINGS.map((plan) => ({
        id: `/api/gouvernance/plannings/${plan.id}`,
        full_name: plan.name,
        value: plan.id,
        text: plan.name,
      }));

      return data;
    },
  },
};
</script>

<style></style>
