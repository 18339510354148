var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('b-card',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"h2 mr-auto text-secondary"},[_vm._v(" Nouvelle Session")]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12"},[_c('modal-actions',{on:{"close":function($event){return _vm.$router.go(-1)}}})],1)])]),_c('div',{staticClass:"shdow mb-2"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('span',{staticClass:"h3 text-secondary"},[_vm._v("Informations générales")]),_c('b-button',{attrs:{"pill":"","type":"submit"},on:{"click":_vm.savedata}},[_vm._v("Confirmer")])],1),_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{staticClass:"font-weight-bold",attrs:{"label":"Nom de session :","label-cols-sm":"3","label-align-sm":"left"}},[_c('b-form-input',{class:{
                  'is-invalid':
                    _vm.$v.newSession.name.$error && _vm.$v.newSession.name.$dirty,
                },attrs:{"type":"text","required":"","placeholder":'Nom de session'},model:{value:(_vm.newSession.name),callback:function ($$v) {_vm.$set(_vm.newSession, "name", $$v)},expression:"newSession.name"}}),(_vm.$v.newSession.name.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newSession.name.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"font-weight-bold",attrs:{"label":"Date :","label-cols-sm":"3","label-align-sm":"left"}},[_c('div',{staticClass:"font-weight-normal"},[_c('date-picker',{class:{
                    'is-invalid form-control p-0':
                      _vm.$v.newSession.date.$error && _vm.$v.newSession.date.$dirty,
                  },staticStyle:{"padding-right":"0 !important"},attrs:{"value":new Date(),"placeholder":'Date de création',"format":"DD/MM/YYYY"},model:{value:(_vm.newSession.date),callback:function ($$v) {_vm.$set(_vm.newSession, "date", $$v)},expression:"newSession.date"}}),(_vm.$v.newSession.date.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newSession.date.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1)])],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{staticClass:"font-weight-bold",attrs:{"label":"Planning :","label-cols-sm":"3","label-align-sm":"left"}},[_c('div',{staticClass:"font-weight-normal"},[_c('v-select',{class:{
                    'is-invalid form-control p-0':
                      _vm.$v.newSession.planning.$error &&
                      _vm.$v.newSession.planning.$dirty,
                  },staticStyle:{"padding-right":"0 !important"},attrs:{"options":_vm.planning,"label":"text","reduce":(plan) => plan.value,"placeholder":"Choisir une Planning","name":"role"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newSession.planning),callback:function ($$v) {_vm.$set(_vm.newSession, "planning", $$v)},expression:"newSession.planning"}}),(_vm.$v.newSession.planning.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newSession.planning.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1)])],1)])]),_c('hr',{staticStyle:{"position":"relative","top":"15px"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-tabs',{staticClass:"tab-solid tab-solid-primary mt-3"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('h4',{staticClass:"h4 text-secondary my-3"},[_vm._v(" Liste des Inscriptions ")])])])]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end align-btn-table-2"},[_c('button',{staticClass:"btn h1 mb-2 test-btn btn-secondary d-flex align-items-center",staticStyle:{"background-color":"rgb(0, 123, 255) !important","border-color":"rgb(0, 123, 255) !important"},attrs:{"type":"button","variant":"secondary","disabled":""}},[_c('i',{staticClass:"mdi mdi-plus mr-2"}),_vm._v(" Nouvelle inscription ")])]),_c('div',{staticClass:"col-12"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])]),_c('b-tab',{attrs:{"title":"Inscrits"}},[_c('session-inscription-table',{attrs:{"items":[],"disabled":true}})],1)],1)],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }